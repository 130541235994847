import React, { Component } from "react";

import styles from "./MobileNav.module.scss";

import MenuClosed from "../../images/menu_closed.svg";
import MenuOpen from "../../images/menu_open.svg";

class MobileNav extends Component {
  constructor(props) {
    super(props);
    this.state = { menuClosed: true };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(prevState => ({
      menuClosed: !prevState.menuClosed,
    }));
  }

  render() {
    const { className, children } = this.props;
    const { menuClosed } = this.state;

    return (
      <div className={className}>
        <div onClick={this.handleClick} className={styles.menuToggle}>
          {menuClosed && <img className={styles.menuClosed} src={MenuClosed} />}
          {!menuClosed && <img className={styles.menuOpen} src={MenuOpen} />}
        </div>

        <ul className={`${styles.menu} ${menuClosed ? styles.menuClosed : ""}`}>
          {children}
        </ul>
      </div>
    );
  }
}

export default MobileNav;
