import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Logo from "../../images/logo_trelinska.svg";
import MobileNav from "./MobileNav";

import styles from "./Nav.module.scss";

const getMenuItems = () => {
  return (
    <>
      <li key="home" className={styles.item}>
        <Link to="/" className={styles.link}>
          {"HOME"}
        </Link>
      </li>
      <li key="portfolio" className={styles.item}>
        <Link to="/portfolio" className={styles.link}>
          {"PORTFOLIO"}
        </Link>
      </li>
      <StaticQuery
        query={graphql`
          query {
            allStrapiPage {
              edges {
                node {
                  sorting_id
                  strapiId
                  title
                  link
                }
              }
            }
          }
        `}
        render={data =>
          data.allStrapiPage.edges
            .sort((a, b) => a.node.sorting_id - b.node.sorting_id)
            .map((page, i) => {
              return (
                <li key={page.node.strapiId} className={styles.item}>
                  <Link to={`/${page.node.link}`} className={styles.link}>
                    {page.node.title}
                  </Link>
                </li>
              );
            })
        }
      />
    </>
  );
};

const Nav = () => (
  <div className={styles.self}>
    <div className={styles.placeholder} />
    <nav className={styles.nav}>
      <div className={styles.logoContainer}>
        <Link className={styles.logoLink} to="/">
          <img className={styles.logo} src={Logo} alt="Trelińska" />
        </Link>
      </div>

      <MobileNav className={styles.mobileMenu}>{getMenuItems()}</MobileNav>
      <ul className={styles.desktopMenu}>{getMenuItems()}</ul>
    </nav>
  </div>
);

export default Nav;
