import React from "react";
import PropTypes from "prop-types";

import Nav from "../Nav";
import Seo from "../seo";
import Footer from "../Footer/index";

import "../../assets/css/global.css";
// import "../../assets/css/reset.css";

import styles from "./Layout.module.scss";

const Layout = ({ children, fullscreen, fullscreenDesktop, seoOptions }) => {
  return (
    <div
      className={`${styles.self} ${fullscreen ? styles.fullscreen : ""} ${
        fullscreenDesktop ? styles.fullscreenDesktop : ""
      }`}
    >
      <div className={styles.inner}>
        <Seo {...seoOptions} />
        <Nav />
        <main className={styles.main}>{children}</main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
