import React from "react";
import { StaticQuery, graphql } from "gatsby";

import styles from "./Footer.module.scss";

const Footer = () => (
  <footer className={styles.self}>
    <ul className={styles.list}>
      <StaticQuery
        query={graphql`
          query {
            allStrapiSocial {
              edges {
                node {
                  strapiId
                  name
                  link
                  sorting_id
                }
              }
            }
          }
        `}
        render={data =>
          data.allStrapiSocial.edges
            .sort((a, b) => a.node.sorting_id - b.node.sorting_id)
            .map(social => {
              return (
                <li className={styles.element} key={social.node.strapiId}>
                  <a
                    className={styles.link}
                    href={social.node.link}
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    {social.node.name}
                  </a>
                </li>
              );
            })
        }
      />
    </ul>
  </footer>
);

export default Footer;
